<template>
  <div class="solutionMind">
    <div class="solutionTop">
      <el-carousel class="solutionTop" indicator-position="outside">
        <el-carousel-item class="solutionTop" v-for="i in imgList" :key="i.id">
          <img :src="i.url" class="solutionTopImg" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="solutionBody">
      <img v-for="s in bodyImg" :key="s.id" :src="s.url" class="solutionBodyImg">
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      imgList: [
        {
          id: 0,
          url: "https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230112151626_tiop.png",
        },
      ],
      bodyImg: [
        {id:0,url:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230112151945_3.png'},
        {id:1,url:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230112152005_4.png'},
        {id:2,url:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230112152025_5.png'},
        {id:3,url:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230112152130_11.png'},
        {id:4,url:'https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230112152156_12.png'},
      ]
    }
  }
}
</script>

<style>
.solutionMind{
  width: 100%;
  height: 100%;
}

.solutionTop{
  width: 100%;
  height: 800px;
}

.el-carousel__container {
  width: 100%;
  height: 760px;
}

.solutionTopImg{
  width: 100%;
  height: 100%;
}

.solutionBody{
  width: 100%;
  min-height: 200px;
  display: block;
}

.solutionBodyImg{
  width: 100%;
  display: block;
}
</style>